/*==================================
home
==================================*/
.home_values_accessibility
{
    background: url("../../public/assets/page/home/ourValues_accessibility.png");
    background-size: cover;
}

.home_values_transparency
{
    background: url("../../public/assets/page/home/ourValues_transparency.png");
    background-size: cover;
}

.home_values_security
{
    background: url("../../public/assets/page/home/ourValues_security.png");
    background-size: cover;
}

.homeHeader
{
    text-align: center;
}

.launchHeader
{
    color: var(--MV_palette_moonYellow);
    text-align: center;
}
/*==================================
earn/token
==================================*/
.earnToken_warning
{
    background: var(--MV_palette_moonYellow) !important;
	width: 100%;
}

.earnToken_buySell
{
    width: 300px;
    height: 400px;
    text-align: center;
}

.earnToken_button
{
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.earnToken_stats
{
    justify-content: space-between;
}

.earnToken_breakdown
{
	height: 300px;
}

/*==================================
Bank
==================================*/
.bankUserDeposit__mobile
{
    order: -1
}
/*==================================
Escrow
==================================*/
.escrowContainer
{
    padding-right: 10px;
    padding-left: 10px;
}

.escrowContainer.escrowContainer__desktop
{
    padding-right: 0;
    padding-left: 0;
}

.currentTrade
{
    display: none;
}

.currentTrade.currentTrade__desktop
{
    display: flex;
}

.currentTrade_Active
{
    display: flex;
    order: 2;
}
/*==================================
Misc
==================================*/
.chart
{
    border: 2px solid var(--MV_palette_moonYellow);
}

.dottedHover
{
    border-bottom: 0px;
}

.dottedHover:hover
{
    border-bottom: 1px dotted var(--MV_palette_moonYellow);
}