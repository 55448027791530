.root
{
	/* PALETTE */
	--MV_palette_black: #060606;
	--MV_palette_darkGrey: #131313;
	--MV_palette_darkGrey2: #1d1d1e;
	--MV_palette_lightGrey: #565554;
	--MV_palette_white: #f5f5f5;
	--MV_palette_moonYellow: #f7a91d;

	/* SETTING */
	--MV_settings_radius: 6px;
	--MV_settings_radiusSmall: 6px;

	/* PALETTE CHARTS */
	--MV_palette_chart_color1: #f7a91d;
	--MV_palette_chart_color2: #1969ff;
	--MV_palette_chart_color3: #6236ff;


    /* GENERAL */
    --ML_page_font: Poppins;
	--ML_page_fontSize: 16px;
	--ML_page_backgroundColor: transparent;
    --ML_page_background: transparent;
    --ML_page_colorPrimary: var(--MV_palette_white);
    --ML_page_colorSecondary: #BBBBBB;
    --ML_page_colorAccent: var(--MV_palette_moonYellow);
    --ML_page_link: var(--MV_palette_moonYellow);
    --ML_page_linkSecondary: #f5f5f5;
	--ML_page_linkAccent: var(--ML_palette_moonYellow);
    --ML_page_grayedOut: #607068;
    --ML_page_scrollBar: var(--MV_palette_black);
    --ML_page_scrollThumb: var(--MV_palette_moonYellow);
    --ML_page_placeholderColor: var(--MV_palette_lightGrey);

    /* TOOLBAR */
    --ML_toolBar_padding: 16px;
    --ML_toolBar_border: 0px;
    --ML_toolBar_background: var(--MV_palette_black);
    --ML_toolBar_height: 50px;

    /* MENU */
    --ML_menu_border: 1px solid var(--ML_page_colorAccent);
    --ML_menu_shadow: 0px 2px 10px -8px rgb(0 0 0 / 20%), 0px 1px 10px -8px rgb(0 0 0 / 14%), 0px 1px 10px -8px rgb(0 0 0 / 12%);
    --ML_menu_borderRadius: 16px;
    --ML_menu_background: var(--MV_palette_black);
    --ML_menu_logo_height: 45px;

    /* MENUITEM */
    --ML_menuItem_height: var(--ML_toolBar_height);
    --ML_menuItem_padding: 16px;
    --ML_menuItem_font: var(--ML_page_font);
    --ML_menuItem_size: var(--ML_size_normal);
    --ML_menuItem_weight: var(--ML_weight_normal);
    --ML_menuItem_color: var(--MV_palette_white);
    --ML_menuItem_hover_size: var(--ML_menuItem_size);
    --ML_menuItem_hover_weight: var(--ML_menuItem_weight);
    --ML_menuItem_hover_color: #FFFFFF88;
    --ML_menuItem_hover_background: rgba(255,255,255,.05);

    /* PAGE HEADER */
    --ML_pageHeader_background: rgba(0,0,0,.2);
    --ML_pageHeader_border: 1px solid var(--ML_page_colorAccent);

    /* TOOLTIP */
    --ML_tooltip_background: var(--MV_palette_darkGrey);
    --ML_tooltip_border: 1px solid var(--ML_page_colorAccent);
    --ML_tooltip_borderRadius: 5px;

    /* MODAL */
	--ML_modal_overlayBackground: #00000099;
	--ML_modal_overlayFilter: blur(5px);
    --ML_modal_border: 2px solid #000;
    --ML_modal_borderRadius: 25px;
	--ML_modal_closeButtonBorder: 1px solid var(--ML_page_colorAccent);
    --ML_modal_closeButtonColor: var(--MV_palette_white);
    --ML_modal_closeButtonBackground: transparent;
    --ML_modal_background: var(--MV_palette_darkGrey);
    --ML_modal_headerColor: var(--ML_page_colorPrimary);
    --ML_modal_headerFontSize: var(--ML_size_normal);
    --ML_modal_headerFontWeight: var(--ML_weight_bold);
    --ML_modal_headerBorder: 1px solid var(--ML_page_colorAccent);
    --ML_modal_headerBackground: transparent;
    --ML_modal_contentColor: var(--ML_page_colorPrimary);
    --ML_modal_contentFontSize: var(--ML_size_normal);
    --ML_modal_contentFontWeight: var(--ML_weight_normal);
    --ML_modal_footerBackground: transparent;
    --ML_modal_footerColor: var(--ML_page_colorPrimary);
    --ML_modal_footerFontSize: var(--ML_size_normal);
    --ML_modal_footerFontWeight: var(--ML_weight_normal);

    /* CARD */
    --ML_card_border: 0px;
    --ML_card_border_radius: 10px;
    --ML_card_header_color: var(--ML_page_colorPrimary);
    --ML_card_header_size: var(--ML_size_normal);
    --ML_card_header_weight: var(--ML_weight_normal);
    --ML_card_header_background: var(--MV_palette_darkGrey2);
    --ML_card_body_color: var(--ML_page_colorPrimary);
    --ML_card_body_size: var(--ML_size_normal);
    --ML_card_body_weight: var(--ML_weight_normal);
    --ML_card_body_background: var(--MV_palette_darkGrey);
    --ML_card_footer_color: var(--ML_page_colorPrimary);
    --ML_card_footer_size: var(--ML_size_normal);
    --ML_card_footer_weight: var(--ML_weight_normal);
    --ML_card_footer_background: var(--ML_card_header_background);

    /* CARD RIBBON */
    --ML_card_ribbon_right: 10px;
    --ML_card_ribbon_top: -3px;
    --ML_card_ribbon_background: #2ca7d8;
    --ML_card_ribbon_width: 32px;
    --ML_card_ribbon_height: 36px;

    /* BUTTON */
	--ML_button_size: 42px;
	--ML_button_sizeSmall: 34px;
    --ML_button_padding: 9.5px;
	--ML_button_paddingSmall: 8px;
    --ML_button_border_size: 1px;
    --ML_button_border_color: var(--MV_palette_moonYellow);
    --ML_button_radius: var(--MV_settings_radius);
	--ML_button_radiusSmall: var(--MV_settings_radiusSmall);
    --ML_button_background: var(--MV_palette_moonYellow);
    --ML_button_hover_background: var(--MV_palette_moonYellow);
    --ML_button_color: var(--ML_page_colorPrimary);
    --ML_button_stroke: 0px;
    --ML_button_backgroundPrimary: var(--MV_palette_black);
    --ML_button_hover_backgroundPrimary: var(--MV_palette_black);
    --ML_button_colorPrimary: var(--MV_palette_white);
	--ML_button_border_colorPrimary: var(--MV_palette_lightGrey);
    --ML_button_strokePrimary: 0px;
    --ML_button_backgroundCancel: var(--MV_palette_lightGrey);
    --ML_button_hover_backgroundCancel: var(--MV_palette_lightGrey);
    --ML_button_colorCancel: var(--ML_page_colorPrimary);
    --ML_button_strokeCancel: 0px;
	--ML_button_border_colorCancel: var(--MV_palette_lightGrey);

	/* MODAL BUTTON */
	--ML_modalButton_backgroundCancel: var(--MV_palette_lightGrey);
    --ML_modalButton_hover_backgroundCancel: var(--MV_palette_lightGrey);
    --ML_modalButton_colorCancel: var(--ML_page_colorPrimary);
    --ML_modalButton_strokeCancel: 0px;
	--ML_modalButton_border_colorCancel: var(--MV_palette_lightGrey);

	/* TOOL BUTTON */
	--ML_toolButton_color: var(--MV_palette_white);
	--ML_toolButton_border_size: 1px;
	--ML_toolButton_border_color: var(--MV_palette_moonYellow);

    /* CHECKBOX */
    --ML_checkBox_size: 25px;
	--ML_checkBox_checkMark_color: var(--MV_palette_moonYellow);

    /* INPUT */
    --ML_input_color: var(--ML_page_colorPrimary);
    --ML_input_weight: var(--ML_weight_normal);
    --ML_input_size: var(--ML_size_normal);
    --ML_input_border_color: var(--MV_palette_lightGrey);
    --ML_input_border: 1px solid var(--ML_input_border_color);
	--ML_input_border_radius: var(--MV_settings_radius);
	--ML_input_border_radiusSmall: var(--MV_settings_radiusSmall);
    --ML_input_background: transparent;
    --ML_input_shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
    --ML_input_padding: 12px;

    /* TOGGLE */
    --ML_toggle_width: 36px;
    --ML_toggle_height: 20px;
    --ML_toggle_thumbSmaller: 4px;
    --ML_toggle_shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
    --ML_toggle_borderSize: 1px;
    --ML_toggle_border: solid var(--MV_palette_lightGrey);
    --ML_toggle_thumbColor: rgb(39, 38, 44);
	--ML_toggle_thumbColorDisabled: var(--MV_palette_moonYellow);
    --ML_toggle_thumbShadow: rgb(118 69 217) 0px 0px 0px 1px, rgb(118 69 217 / 60%) 0px 0px 0px 4px;
    --ML_toggle_backgroundEnabled: var(--ML_page_colorAccent);
    --ML_toggle_backgroundDisabled:rgba(255,255,255,.05);

    /* TEXTTOGGLE */
    --ML_textToggle_itemSmaller: 4px;
	--ML_textToggle_itemPaddingHeight: 7.5px;
	--ML_textToggle_backgroundDisabled: transparent;
    --ML_textToggle_colorEnabled: var(--MV_palette_white);
    --ML_textToggle_colorDisabled: var(--ML_page_colorPrimary);
    --ML_textToggle_weight: var(--ML_weight_normal);
    --ML_textToggle_size: var(--ML_size_normal);

    /* SELECTBOX */
    --ML_selectBox_color: var(--ML_page_colorPrimary);
    --ML_selectBox_weight: var(--ML_weight_normal);
    --ML_selectBox_size: var(--ML_size_normal);
    --ML_selectBox_border_color: var(--MV_palette_lightGrey);
    --ML_selectBox_border: 1px solid var(--ML_selectBox_border_color);
    --ML_selectBox_background: transparent;
    --ML_selectBox_shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
    --ML_selectBox_padding: 8px;
	--ML_selectBox_button_color: var(--MV_palette_white);
	--ML_selectBox_button_background: var(--MV_palette_moonYellow);
    --ML_selectBox_menu_background: var(--MV_palette_black);
    --ML_selectBox_menu_border: 1px solid var(--MV_palette_moonYellow);
    --ML_selectBox_menu_borderRadius: 16px;
    --ML_selectBox_menu_hover_size: var(--ML_menuItem_size);
    --ML_selectBox_menu_hover_weight: var(--ML_menuItem_weight);
    --ML_selectBox_menu_hover_color: #FFFFFF88;
    --ML_selectBox_menu_hover_background: rgba(255,255,255,.05);

	/* CHARTS TOOLTIP */
	--ML_charts_tooltip_background: var(--MV_palette_black);
	--ML_charts_tooltip_border: 1px solid var(--MV_palette_moonYellow);
    --ML_charts_tooltip_borderRadius: 5px;
	--ML_charts_tooltip_padding: 5px;
	--ML_charts_tooltip_color: var(--ML_page_colorPrimary);

    /* EMBLEM */
    --ML_emblem_fontWeight: var(--ML_weight_normal);











    /* FONT SIZE */
    --ML_size_small: 0.75rem;
    --ML_size_normal: 0.875rem;
	--ML_size_big: 1.5rem;
	--ML_size_veryBig: 2rem;
    --ML_size_xl: 3em;
	--ML_size_xxl: 4.5em;
    --ML_size_menuActive: 1.25rem;
	--ML_size_button: var(--ML_size_normal);
	--ML_size_buttonSmall: var(--ML_size_small);

    /* FONT WEIGHT */
    --ML_weight_light: normal;
    --ML_weight_normal: bold;
    --ML_weight_bold: bolder;
	--ML_weight_button: bold;

    /* LINE HEIGHT */
    --ML_lineHeight_normal: 1.5;

    /* COLORS */
    --ML_color_green: rgb(38, 191, 64);
    --ML_color_red: rgb(197, 40, 62);
    --ML_color_yellow: #ffae00;

    /* SPECIAL */
    --ML_positive: rgb(38, 191, 64);
    --ML_negative: rgb(197, 40, 62);
    --ML_evenOdd: rgba(200, 200, 200, 0.1);
}