.main
{
    width: 100%;
    min-height: 100vh;
    /*padding: var(--ML_toolBar_height) 0;*/
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}